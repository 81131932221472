<template>
	<!-- 单词速记 -->
	<div class="com_conbox wordlinkcom">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="" />
		</audio>
		<audio controls loop ref="bgmusicaudioCom" class="disnone">
			<source :src="bgmusic" />
		</audio>
		<div class="b_titlebox" v-if="showtype>0">
			<div class="box1">
				<span class="span fontsize32">单词连连</span>
			</div>
		</div>
		<div class="imgclose" @click="handleclose">
			<img class="img100" src="../../assets/static/Frame3465600.png"/>
		</div>
		<!-- 音频 -->
		<div class="content-panel">
			<div class="block30"></div>
			<!-- 第一轮 -->
			<div class="startbox" v-if="showtype==0">
				<div class="b_titlebox">
					<div class="box1"><span class="span fontsize32">单词连连</span></div>
				</div>
				<div class="imgbox"><img class="icon" src="../../assets/logo1.png"/></div>
				<div class="title1">点击选择互相对应的中文和英文！</div>
				<div class="btn1" @click="handlenext(1)">Let‘s go！</div>
			</div>
			<!-- 内容连连 -->
			<div class="studylian_box" v-if="showtype==1">
				<div class="num_soundbox">
					<div class="box1">
						<div class="text text1">提交次数：{{studyNum}}</div>
						<div class="text text2">答错次数：{{cuoNum}}</div>
					</div>
					<div class="box2" @click="handleOpenMusic">
						<img v-if="isLinstening" class="img100" src="@/assets/static/linsten.png"/>
						<img v-if="!isLinstening" class="img100" src="@/assets/static/linsten_close.png"/>
					</div>
				</div>
				<div class="timerbox">剩余时间：{{timerText}}</div>
				<div class="datalist">
					<div class="leftbox">
						<div class="item" v-for="(item,index) in linkwordList" :key="index" v-if="item.linktype==0">
							<transition name="el-zoom-in-center">
								<div v-show="item.isshow" class="itemobj fontsize24" :class="{'active':item.isselect}" @click="handleitem(index)">
									<span class="clamp" v-if="item.linktype==0">{{item.word}}</span>
									<!-- <span class="clamp" v-if="item.linktype==1">{{item.partName}}{{item.linkname}}</span> -->
								</div>
							</transition>
						</div>
					</div>
					<div class="rightbox">
						<div class="item" v-for="(item,index) in linkwordList" :key="index" v-if="item.linktype==1">
							<transition name="el-zoom-in-center">
								<div v-show="item.isshow" class="itemobj fontsize24" :class="{'active':item.isselect}" @click="handleitem(index)">
									<!-- <span class="clamp" v-if="item.linktype==0">{{item.word}}</span> -->
									<span class="clamp" v-if="item.linktype==1">{{item.partName}}{{item.linkname}}</span>
								</div>
							</transition>
						</div>
					</div>
					
				</div>
				<div class="transition_good">
					 <transition name="run">
					    <img v-if="isGood" key="good" src="@/assets/static/good.png" alt="good图片" class="zoom-in like-image">
					</transition>
				</div>
			</div>
			<!-- 完成 -->
			<div class="finish_box" v-if="showtype>1">
				<div class="title1">
					<img v-if="showtype==2" class="img100" src="../../assets/static/Group42732083.png"/>
					<img v-if="showtype==3" class="img100" src="../../assets/static/Group427320832.png"/>
				</div>
				<div class="desc1">
					<span v-if="showtype==2">太棒了，您已经全部选对啦！继续加油哦！</span>
					<span v-if="showtype==3">同学您还需继续努力！加油！</span>
				</div>
				<div class="conbox">
					<div class="text text1">总用时：<span>{{useSeconds}}</span>秒</div>
					<div class="text text2">答错<span>{{cuoNum}}</span>次</div>
					<div class="text text3">提交<span>{{studyNum}}</span>次</div>
				</div>
				<div class="btnbox">
					<div class="btn1 btn2" @click="handleagain">再玩一次</div>
					<div class="btn1" @click="handlesuccess">关闭</div>
				</div>
			</div>
			
		</div>
	</div>
	<!-- end-->
</template>

<script>
	import {mapState} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		props: {
			type: {
				type: Number,
				default: () => {
					return 1;
				}
			},
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		watch: {
			list: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal&&newVal.length>0){
						// console.log(newVal)
						this.wordList = JSON.parse(JSON.stringify(newVal))
						this.initfunc()
					}
			    },
			},
		},
		data() {
			return {
				showtype:0,//步骤
				isLinstening: false, //是否正在播放音频
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				awtitloading: false, //防止多次点击
				timer:null,
				totalSeconds: 90,
				useSeconds: 0,
				timerText:"01:30",//时间
				isGood:false, //点赞
				cuoNum:0,//错次
				duiNum:0,//dui次
				studyNum:0,//错次
				linkwordList:[],//链接的十个单词列表
				bgmusic:"https://hlaxoss.oss-cn-hangzhou.aliyuncs.com/axmp3/bjyy-yx.MP3",
				goodmusic:"https://hlaxoss.oss-cn-hangzhou.aliyuncs.com/axmp3/good-xly.MP3",
			};
		},
		//状态值
		computed: {
			...mapState(['myReadingObj','userInfo', 'isLogin']),
		},
		beforeDestroy() {
		    // 清除定时器
			if(this.timer){
				clearInterval(this.timer);
			}
		},
		methods: {
			//初始化
			initfunc(){
				var _this = this
				this.wordList.forEach((item, index) => {
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
				});
				var linkwordList = []
				var linkwordList2 = []
				var linklist = this.getRandomItems(this.wordList,10)
				linklist.forEach((item, index) => {
					var item1 = {
						linktype:0,
						isshow:true,
						isselect:false,
						word:item.word,
						wordTpye:item.wordTpye,
					}
					if(item1.wordTpye.length>0){
						item1["partName"] = item1.wordTpye[0].part
						item1["linkname"] = item1.wordTpye[0].means.join('; ')
					}
					linkwordList.push(item1)
					var item2 = {
						linktype:1,
						isshow:true,
						isselect:false,
						word:item.word,
						wordTpye:item.wordTpye,
						partName:item1.partName,
						linkname:item1.linkname
					}
					linkwordList2.push(item2)
				});
				var newlist = linkwordList2.concat(linkwordList)
				this.linkwordList = this.shuffleArray(newlist)
				//console.log(this.linkwordList)
				this.wordindex = 0
				this.showtype = 0 //未开始
				this.awtitloading = false
				this.cuoNum = 0//错次
				this.studyNum = 0//错次
				this.duiNum = 0
				this.totalSeconds = 90
				this.useSeconds = 0
				this.timerText = "01:30"//时间
				//刷新页面单词
				this.refreshpage()
				//播放背景音乐
				if(!this.isLinstening){
					this.handleOpenMusic()
				}
			},
			//播放背景音乐
			handleOpenMusic(){
				var _this = this
				try{
					_this.isLinstening = !_this.isLinstening
					_this.$nextTick(res => {
					    if(_this.isLinstening){
							_this.$refs.bgmusicaudioCom.play()
					    }else{
					   	    _this.$refs.bgmusicaudioCom.pause()
					    }
					})
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$nextTick(res => {
						_this.$refs.commonaudioCom.src = src;
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//再来一次
			handleagain(){
				showLoading()
				this.wordList = []
				this.wordList = JSON.parse(JSON.stringify(this.list))
				this.initfunc()
				hideLoading()
			},
			//点击
			handleitem(index){
				var _this = this
				if(this.awtitloading){
					return false;
				}
				this.awtitloading = true
				if(this.linkwordList[index].isselect){
					this.linkwordList[index].isselect = false
					this.awtitloading = false
					return false;
				}
				this.linkwordList[index].isselect = true
				var sellist = this.linkwordList.filter(fitem => fitem.isselect);
				//console.log(sellist)
				if(sellist.length == 2){
					//var wordlist = this.linkwordList.filter(fitem => fitem.wordId == this.linkwordList[index].wordId);
					if(sellist[0].word==sellist[1].word){
						this.duiNum +=2
						this.toggleImage()
						var timer = setTimeout(() => {
							clearTimeout(timer)
							_this.linkwordList.forEach((item, index) => {
								if(item.word==sellist[0].word){
									item.isshow = false
								}
								item.isselect = false
							});
							_this.awtitloading = false
							//完成
							if(_this.duiNum==_this.linkwordList.length){
								_this.showtype = 2
								//训练完成回调
								_this.successfunc()
								if(_this.timer){
									clearInterval(_this.timer);
								}
							}
						}, 1000);
						this.studyNum += 1
					}else{
						var timer = setTimeout(() => {
							clearTimeout(timer)
							_this.linkwordList.forEach((item, index) => {
								item.isselect = false
							});
							_this.awtitloading = false
						}, 358);
						this.studyNum += 1
						this.cuoNum += 1//错次
					}
				}else{
					this.awtitloading = false
				}
			},
			//完成
			handlesuccess(){
				if(this.showtype == 2 && this.type == 2){
					this.$emit("comfirm")
				}else{
					if(this.type==1){
						this.$emit("comfirm")
					}
					this.$emit('onClose')
				}
			},
			//训练完成回调
			successfunc(){
				//保留学习时长
				var params = {
					userUuid:this.userInfo.uuid,
					studyTime:this.studytime
				}
				this.$common.saveStudyTime(params)
				//完成训练
				var params = {
					recordType:1,//测试类型 0:单词速记 1:单词连连 2:听音拼写 3:单元测试
					bookId:this.wordobj.bookId,
					booksLessonId:this.wordobj.lessonId,
					userUuid:this.userInfo.uuid,
				}
				this.$common.saveBooksLessonRecord(params)
			},
			toggleImage() {
				this.playAudioCom(this.goodmusic)
				this.isGood = !this.isGood;
			    if (this.isGood){
			        var timer = setTimeout(() => {
						clearTimeout(timer)
			          this.isGood = false; // 0.5秒后图片消失
			        }, 1000);
			    }
			},
			// 更新倒计时
			updateCountdown() {
				if (this.totalSeconds > 0) {
				    this.totalSeconds -= 1;
					this.useSeconds +=1
					let minutes = Math.floor(this.totalSeconds / 60);
					let seconds = this.totalSeconds % 60;
					minutes = minutes < 10 ? '0' + minutes : minutes;
					seconds = seconds < 10 ? '0' + seconds : seconds;
					this.timerText = minutes + ':' + seconds;
				} else {
					this.totalSeconds = 90
					this.timerText = "01:30" //时间
					this.useSeconds  = 90
					if(this.timer){
						clearInterval(this.timer);
					}
				    this.showtype = 3
				}
			},
			//关闭
			handleclose(){
				var _this = this
				if(this.showtype>0){
					this.$confirm("当前处于学习中，确认是否退出？", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.$emit('onClose')
					}).catch(() => {});
				}else{
					this.$emit('onClose')
				}
			},
			//下一个
			handlenext(type){
				this.showtype = type
				if(type>0&&this.studyTimer==null){
					if(this.timer){
						clearInterval(this.timer);
					}
					this.totalSeconds = 90
					this.timerText = "01:30"//时间
					this.useSeconds = 0
					// 创建定时器
					this.timer = setInterval(this.updateCountdown, 1000);
				}
				//刷新页面单词
				this.refreshpage()
			},
			//刷新页面单词
			refreshpage(){
				this.wordobj = this.wordList[this.wordindex]
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			//随机取十个
			getRandomItems(arr,getnum) {
			    const length = arr.length;
			    if (length <= getnum) {
			        return arr;
			    }
			    const randomItems = [];
			    while (randomItems.length < getnum) {
			        const randomIndex = Math.floor(Math.random() * length);
			        const item = arr[randomIndex];
					const findindex = randomItems.findIndex(fitem => fitem.wordId == item.wordId);
			        if (findindex === -1) {
						randomItems.push(item);
			        }
			    }
			    return randomItems;
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-input__inner{
		border: none;
	}
	.com_conbox {
		width: 100%;
		position: relative;
		z-index: 18;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
	}
	.b_titlebox{
		width: 263px;
		height: 80px;
		border-radius: 20px;
		background: linear-gradient(90deg, #E8F0FB 0%, #99B8E3 100%);
		box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: -18px;
		top: -28px;
		z-index: 2;
		.box1{
			width: 231px;
			height: 62px;
			border-radius: 20px;
			background: linear-gradient(180deg, #2BB9FF 0%, #06F 100%);
			box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
			.span{
				color: #FFF;
				text-align: center;
			}
		}
	}
	.content-panel {
		width: 100%;
		min-height: 678px;
		position: relative;
		z-index: 1;
		padding: 28px 16px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.startbox{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.b_titlebox{
				position: relative !important;
				left: 0;
				top: 0;
			}
			.imgbox{
				width: 82%;
				height: 185px;
				margin-top: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.icon{
					width: 210px;
					height: 137px;
				}
			}
			.title1{
				margin-top: 32px;
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		.studylian_box{
			width: 100%;
			height: 100%;
			.num_soundbox{
				position: absolute;
				right: 158px;
				top: 25px;
				z-index: 2;
				cursor: pointer;
				display: flex;
				align-items: center;
				.box1{
					width: 392px;
					height: 48px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 34px;
					background: linear-gradient(180deg, #FFDE16 0%, #FC8901 100%);
					box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
					.text{
						color: #FFF;
						font-size: 20px;
					}
					.text2{
						margin-left: 28px;
					}
				}
				.box2{
					margin-left: 18px;
					width: 48px;
					height: 48px;
				}
			}
			.timerbox{
				padding: 18px 16px 0;
				color: #273B90;
				font-size: 20px;
			}
			.datalist{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				.leftbox,.rightbox{
					width: 50%;
					display: flex;
					flex-wrap: wrap;
				}
				.item{
					width: 258px;
					height: 100px;
					margin: 16px;
				}
				.itemobj{
					width: 100%;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #000;
					border-radius: 20px;
					background: #FFF;
					box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
					cursor: pointer;
					&.active{
						border: 3px solid #0E80FF;
						background: linear-gradient(180deg, #FFF 0%, #B9CCFF 100%);
						box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
						color: #0E80FF;
					}
					span{
						padding: 18px;
						box-sizing: border-box;
					}
				}
			}
			.transition_good{
				.like-image {
					width: 162px;
					height: 79px;
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -81px;
					margin-top: -38px;
					z-index: 2;
				}
				.run-enter-active {
				    animation: run-scale 1s linear 0s;
				}
				// 离开的时候设置成相反哒
				// .run-leave-active {
				//     animation: run-scale 1s linear 0s reverse;
				// }
				@keyframes run-scale {
					0% {
					  transform: scale(0);
					}
					50% {
					  transform: scale(1.3);
					}
					100% {
					  transform: scale(1);
					}
				}
			}
		}
		.finish_box{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title1{
				margin-top: 18px;
				width: 192px;
				height: 63px;
			}
			.desc1{
				margin-top: 48px;
				color: #FFF;
				text-align: center;
				font-size: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
			.conbox{
				width: 82%;
				height: 185px;
				margin-top: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.text{
					color: #FFF;
					font-size: 28px;
					span{
						color: #273B90;
					}
				}
				.text2,.text3{
					margin-left: 28px;
				}
			}
			.btnbox{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn2{
					margin-right: 28px;
				}
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		
	}
	
</style>